<template>
  <div class="div-block-7 article-content-subpage">
    <div class="ui small breadcrumb">
      <i class="left angle icon divider divider--subpage"></i>
      <router-link :to="{ name: 'activities' }" class="active section text-meta"
        >Alle Angebote</router-link
      >
    </div>
    <div class="div-block-14">
      <h1>Skifreizeiten</h1>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <p>
          Auch wenn die Sauerländer Berge in den Wintermonaten zahlreiche
          Möglichkeiten für Ski-/und Snowboardabfahrten zur Verfügung stellen,
          bieten wir in jeder Wintersaison gemeinsame Skifreizeiten an.
        </p>
        <p>In der Saison 2022/2023 bieten wir folgende Fahrt an:</p>
        <ul>
          <li>
            <router-link
              :to="{ name: 'event', params: { id: 'mt855zkbqaVezhzVkN7U' } }"
              >Skifreizeit ins Stubaital</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'event', params: { id: 'zbHKSLChx58m5zl7I4os' } }"
              >Sonnenskilaufen in den Dolomiten</router-link
            >
          </li>
        </ul>
        <p>
          Nähere Informationen zu den Fahrten gibt es nach einem Klick auf den
          Namen der Fahrt. Sollte es weitere Rückfragen geben, stehen die
          jeweiligen Ansprechpartner gerne zur Verfügung.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkiHolidays",
  metaInfo: {
    title: "Skifreizeiten",
  },
};
</script>
